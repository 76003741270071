

// import boot from './uikit/api/boot';
// import UIKit from './uikit/uikit'
// import './uikit/icons'
import '../scss/front.less'

    // console.info('ready');
    // boot(UIKit);
window.onload = function() {
    for(const el of document.querySelectorAll('.spinner')) {
        el.classList.remove('spinner');
    }
    for(const el of document.querySelectorAll('.spinner-el')) {
        el.remove('spinner-el');
    }
}
